import { Component } from '@angular/core';
import { TranslateConfigService } from '../app/translate-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent{
  constructor(
    private languageService: TranslateConfigService
  ) {
    this.languageService.setInitialAppLanguage();
  }
}

